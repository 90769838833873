 

body{
    background-color: black;
    color: wheat;
  min-height: 100%;
  height: 100vh;
    margin: 0;
    padding: 10px;

  }

  .Profile_page_main_div{
    width: 90%;
    border: solid 1px wheat;
    margin: auto;
    text-align: center;
    padding: 10px;
  }
  
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    padding: 10px;
    font-size: 30px;
    color: greenyellow;
  }

  p {
    text-align: left !important; 
    padding: 10px;
    font-size: 20px;
  }
  button {
    background-color: greenyellow;
    color: blue;
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 11px;
    padding: 10px;
    border-radius: 20%;
  }
#profile_page_h1, #profile_page_h3, #profile_page_h5{
  margin: 0 !important;
}
img{
  width: 70%;
}
 