body {
  background-color: black;
  color: wheat;
  height: 100%;
  margin: 0;
  padding: 10px;
  overflow-wrap: break-word;
}

.Home_page_main_div {
  width: 90%;
  border: solid 1px wheat;
  margin: auto;
  text-align: center;
}

h1 {
  margin-bottom: 66px;
  text-align: center;
  padding: 10px;
  color: red;
}

h3 {
  margin-bottom: 66px;
  padding: 10px;
  color: white;
}

h4 {
  margin-bottom: 11px;
  padding: 10px;
  color: rgb(25, 255, 25);
}
p {
  text-align: center;
  padding: 10px;
  color: white;
}
a{
  color: aliceblue;
}

#go_ahead{
  width: 90%;
  font-size: 2em;
  color: rgb(0, 0, 0);
  background-color:  rgb(25, 255, 25);
  border-radius: 0%;

}