body {
  background-color: black;
  color: wheat;
  height: 100%;
  margin: 0;
  padding: 10px;
  overflow-wrap: break-word;
}
.Contact_page_main_div {
  width: 90%;
  border: solid 1px wheat;
  margin: auto;
  text-align: center;
  padding: 20px 0;
}
#contact_form{
  padding: 20px 0;
}
#form-email,#form-name, #form-textarea {
  text-align: center;
  padding: 10px;
  margin: 10px;
  width: 70%;
}
.Contact_page{
  margin: auto;
  margin-top: 40px;
  text-align: center;
}
#contact_back_link{
  margin-top: 40px !important;
}
button {
  background-color: greenyellow;
  margin-bottom: 11px;
  padding: 20px 0;
  border-radius: 20%;
}