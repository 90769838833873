body {
    background-color: black;
    color: wheat;
    height: 100%;
    margin: 0;
    padding: 10px;
    overflow-wrap: break-word;
  }
  
  h1 {
    text-align: center;
    padding: 10px;
  }
  b{
    color: greenyellow;
  }
  p {
    text-align: center;
    padding: 10px;
  }
  
  .Terms_page_main_div {
    width: 90%;
    border: solid 1px wheat;
    margin: auto;
    text-align: center;
  }
  
  h1 {
    margin-bottom: 11px;
    padding: 10px;
  }
  
  h3 {
    margin-bottom: 11px;
    padding: 10px;
  }
  
  h4 {
    margin-bottom: 11px;
    padding: 10px;
  }
  
  button {
    background-color: greenyellow;
    margin-bottom: 11px;
    padding: 10px;
    border-radius: 20%;
  }