body{
    background-color: black;
    color: wheat;
    height: 100vh;
    margin: 0;
    padding: 0;
    text-align: center;
  min-height: 100vh;

  }

  .PageNotFound_main_div{
    width: 70%;
    border: solid 1px wheat;
    margin: auto;
    text-align: center;
  }
  h1, h2, h3, h4, h5, h6, .PageNotFound {
    text-align: center;
    padding: 10px;
  }
  h1{
    color: red; 
  }

  p {
    text-align: center !important; 
    padding: 10px;
  }